/*
*
* Accordion Styling. 
* Currently set up for Wordpress Flexible Content ACF fields 
**************************************************************/

.accordion{
	margin:20px 0;
	clear:both;
	
	.accordion-item{
		width:100%;
		padding:0px;
		display: block;

	}

	.accordion-title{
		border:1px solid $c-gray;
		display: block;
		line-height: 30px;
		font-size:1.6rem;
		padding:10px 40px 10px 15px;
		margin:0 0 15px 0;
		cursor: pointer;
		position: relative;
		border-bottom:1px solid darken( $accordion-color, 10% );

		&:after{
			content:'\f067';
			font-family: "FontAwesome";
			text-align: center;
			font-size:1.4rem;
			line-height: 20px;
			display: block;
			position: absolute;
			right:20px;
			top:15px;
			width:20px;
			height:20px;
			background:#fff;
			color:$accordion-color;
			-webkit-border-radius:50%;
			border-radius:50%;
			
		}

		&.open:after{
			content:'\f068';
			font-family: "FontAwesome";
		}
	}

	.inner{
		max-height: 0px;
		overflow: hidden;
		padding:0 20px;
		transition:all .5s;
	
		&[aria-hidden='false']{
			max-height:5000px;
			padding:20px;
		}
	}
}

		