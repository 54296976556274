/*************************************
Homepage
**************************************/

.home{

	.breadcrumbs{display: none;}
	#subnav{display:none;}

	.m-w1.section-inner{
		max-width: none;
		padding:0;
	}

	#content{
		width:100%;
		float:none;
		margin:0;
	}

	.c-w1{
		padding:0;
	}

	.search-form input[type='text']{
		@include media($ex-large-screen-up){
			width:585px;
		}
	}

	.c-w1 h2{
		color:#000;
		font-weight: 800;
		text-align: center;
		margin-bottom: 0.5em;
		@include media($medium-screen-up){
			font-size:4.4rem;
		}
	}

	.home-section .section-inner{
		padding:30px 0;
		@include media($medium-screen-up){
			padding:60px 0;
		}
	}
}

/*******************************
Header Navigation
*******************************/
.home #header .mainnav{
	@include media($ex-large-screen-up){
		display: none;
	}
}


/*******************************
Banner
*******************************/
.home{
	#hero-banner{
		@include media($medium-screen-up){
			height:600px;
		}
	}
}

.banner-text{
	@include media($medium-screen-up){
		position: absolute;
		top:55px;
		left:0;
		width:100%;
	}
	@media screen and (min-width:768px) and (max-width:1300px){
		padding-right:80px;
	}
	@media screen and (min-width:768px) and (max-width:1400px){
		top:28px;
	}

	.inner{
		background:$c-blue;
		padding:20px;
		@include media($medium-screen-up){
			max-width: 400px;
			
			float:right;
			margin-right: 15%;
			padding:0;
			background:transparent;
		}

		@include media($large-screen-up){
			width:50%;
			margin-right: 10%;
		}
		@media screen and (min-width:768px) and (max-width:1400px){
			margin-right: 1%;
			max-width: 520px;
			width:65%;
		}
	}

	h1{
		color:#fff;
		@include media($medium-screen-up){
			font-size:50px;
		}
		@media screen and (min-width:768px) and (max-width:1400px){
			font-size:34px;
			line-height: 1.1;
		}
	}
	p{
		color:#fff;
		font-weight: 600;
		@include media($medium-screen-up){
			font-size:20px;
		}
		@media screen and (min-width:768px) and (max-width:1400px){
			font-size:18px;
			line-height: 1.5;
			margin-bottom: 15px;
		}
	}

	a:not(.btn){
		color:#fff;
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}

	.btn{
		background:#fff;
		color:$c-blue;
		font-size:12px;
		@include media($medium-screen-up){
			font-size:20px;
			padding-top:20px;
			padding-bottom: 20px;
		}
		@media screen and (min-width:768px) and (max-width:1400px){
			font-size:18px;
			padding:12px 15px;
		}

		&:hover{
			background:$c-fern;
			color:#fff;
		}	
	}
}

/**************************************
Home Navigaton
**************************************/
.home .m-w1{ 
	.mainnav{
		position: static;
		margin:0 auto;
		max-width: $max-width;

		.menu{
			display: block;
			margin:20px 0;
			@include media($medium-screen-up){
				display: flex;
				justify-content: space-between; 
				margin:-150px 0 0 0;
			}
			@media screen and (min-width:768px) and (max-width:1400px){
				position:relative;
				margin:-320px 0 0 0;
			}
			@media screen and (min-width:768px) and (max-width:1300px){
				padding-right:80px;
			}
		}

		li{
			width:100%; 
			margin-bottom: 20px;
			padding:0;
			border:2px solid $c-blue;
			@include media($medium-screen-up){
				width:30%;
				margin-bottom: 0;
				border:none;
			}
			&:before{display:none;}
		}

		a{
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height:180px;
			background:#fff;
			font-size:16px;
			border:none;
			text-decoration: none;
			border-bottom:30px solid $c-fern;
			@include media($medium-screen-up){
				font-size:22px;
			}
			@media screen and (min-width:768px) and (max-width:1400px){
				min-height:150px;
				justify-content: flex-start;
				font-size:18px;
			}

			&:after,
			&:before{
				content:'';
				display: block;
				position: absolute;
				left:50%;
				bottom:-30px;
				margin-left:-30px;
				width:60px;
				height:60px;
				border:1px solid #fff;
				border-bottom:none;
				background:$c-blue;
				transition:all .25s;
			}

			&:before{
				border:none;
				left:calc(50% + 2px);
				background:url(../img/icons/icon-arrow-down.png) center center no-repeat;
				transform:rotate(-180deg);
				z-index: 10;
			}

			span{
				font-size:32px;
				@include media($medium-screen-up){
					font-size:40px;
				}
				@media screen and (min-width:768px) and (max-width:1400px){
					font-size:32px;
				}
			}

			&:hover{
				background:#fff;
				color:$c-fern;
				border-color:$c-blue;
				&:before{
					transform:rotate(-90deg);
					bottom:-32px;
					transition:all .25s;
				}

				span{color:$c-fern;}
			}
		}
	}
}


/**************************************
Home Navigaton
**************************************/

.section-icon-list{
	background:#f0f3f5;
	margin-top:-30px;
	padding-top:30px; 

	@media screen and (min-width:768px) and (max-width:1400px){
		margin-left:-10px;
		margin-right:-10px;
	}

	ul.iconlink-list{
		margin:0;
		@include media($medium-screen-up){
			display:flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		li{
			max-width: 210px;
			padding:0;
			margin:20px auto;
			text-align: center;
			@include media($medium-screen-up){
				width:45%;
				margin-bottom: 0;
			}

			@include media($large-screen-up){
				width:22%; 
			}

			&:before{
				display:none;
			}
			
			a{
				display: block;
				text-decoration: none;
			}

			.img-wrap{
				width:205px; 
				height:205px; 
				fill:#fff; 
				padding:30px;
				border-radius:50%;
				border:1px solid $c-fern;
				margin:0 auto;
			}

			
			.fa{
				background:$c-blue;
				color:#fff;
				font-size:60px;
				border-radius:50%;
				width:100%;
				height:100%;
				line-height: 88px;
				padding:25px;
				box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
				transition:all .25s; 
			}

			a:hover .fa{
				background:$c-fern;  
			}

			h3{
				color:$c-fern;
				font-weight: 800;
				@include media($medium-screen-up){
					font-size:5.0rem;
				}
			}

			p{
				color:$c-gray;
				line-height: 1.2em;
				margin: 0;
				@include media($medium-screen-up){
					font-size:2.2rem;
				}
			}
		}
	}
}


.home-section.section-facilitator{

	.section-inner{
		@include media($medium-screen-up){
			display:flex;
			flex-wrap:wrap;
		}
	}
	

	.col{
		text-align: center;
		@include media($medium-screen-up){
			width:50%;
			padding: 30px 2%;
		}
	}

	h2{
		color:$c-fern;
		font-weight:normal;
		margin-bottom: .25em;
		@include media($medium-screen-up){
			font-size:2.4rem;
		}		
	}

	h3{
		color:#000;
		font-weight: 800;
		margin-bottom: .25em;
		@include media($medium-screen-up){
			font-size:4.4rem;
		}
	}

	p{
		font-size:600;
		@include media($medium-screen-up){
			font-size:2.0rem;
		}
	}

	.btn{
		margin-bottom: 40px;
	}

	.more-profiles{
		@include media($medium-screen-up){
			font-size:1.8rem; 
		}
	}


	.img-wrap{
		border:1px solid $c-fern;
		padding:25px;
		display: inline-block;
		
	}

	img{
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
		margin-bottom: 0;
	}
}


.section-blog{
	text-align: center;

	&:before{
		content:'';
		border-top:1px solid $c-blue;
		width:100%;
		max-width: 770px;
		margin:0 auto;
		display: block;
	}

	ul.news-list{
		margin:0 0 40px 0;
		@include media($medium-screen-up){
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		li{
			max-width: 300px;
			margin:0 auto 20px auto;
			padding:0;
			text-align: center;
			@include media($medium-screen-up){
				max-width: none;
				width:32%;
				margin-bottom: 0;
			}
			&:before{
				display: none;
			}
		}

		h3{
			color:$c-gray;
			font-size:1.8rem;
			margin-bottom: 0.65em;
			@include media($medium-screen-up){
				font-size:2.0rem;
			}
		}

		a{
			text-decoration: underline;
			font-size:1.6rem;
			font-weight: 600;
			@include media($medium-screen-up){
				font-size:1.8rem;
			}
			&:hover{
				text-decoration: none;
			}
		}
	}
}

.section-projects{
	@include fillScreen;
	background:#f0f3f5;
	text-align:center;
	padding:30px 0;
	overflow:hidden;

	@include media($medium-screen-up){
		padding:60px 0;
	}

	.section-inner{
		padding:0;
	}

	ul.project-list{
		margin:0;
		.slick-track{
			padding-bottom:20px;
		}

		.slick-slide{
			opacity:.5;
			transition:all .25s;
			&.slick-current{
				opacity: 1;
				transition:all .25s;
			}

			@include media($medium-screen-up){
				&.slick-current{
					opacity:.5;
				}
				&.slick-current-custom{
					opacity: 1;
					transition:all .25s;
				}
			}
		}

		li{
			padding:25px;
			text-align: left;
			background:#fff;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); 
			margin:0 12px;
			@include media($ex-large-screen-up){
				display:flex;
				flex-wrap: wrap;
			}
			

			&:before{
				display: none;
			}
		}

		.thumbnail{
			margin:0 auto;
			max-width: 200px;
			@include media($ex-large-screen-up){
				margin: 0 1.5em 0 0;
				width:172px;
				flex-basis: 33%;
			}
		}

		.content{
			flex: 1;
		}

		h3{
			color:$c-fern;
			font-size:1.8rem;
			font-weight: 800;
			margin-bottom: .25em;
			@include media($medium-screen-up){
				font-size:2.4rem;
			}
		}

		p{
			margin-bottom: .25em;
		}

		.read-more{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}

	.control{
		.section-inner{
			padding:15px 10px;
			position: relative;
		}
	}

	.control-inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.btn{
		@include media($medium-screen-down){
			font-size:1.4rem;
		}
	}

	.slick-arrow{
		//position: absolute;
		position: relative;
		//top:0;
		text-indent: -9999px;
		width:30px;
		height:30px;
		@include media($medium-screen-up){
			width:60px;
			height:60px;
		}

		&:before{
			content:'';
			position: absolute;
			left:0;
			top:2px;
			width:100%;
			height:100%;
			background:url(../img/icons/icon-arrow-down.png)  0 0 no-repeat;
			background-size:100% auto;
		}
		
	}

	.slick-prev{
		//left:10px;
		float:left;
		&:before{
			transform:rotate(90deg) translateX(-4px);
		}
	}

	.slick-next{
		float:right;
		//right:10px;
		&:before{
			transform:rotate(-90deg);
		}
	}

}
