/*******************************************
Layout
********************************************/
   
.m-w1 { 
	@include outer-container;
	padding-bottom:30px;
	@include media($medium-screen-up) {
		padding-bottom:75px;
	}
}

 
#content {
	@include clearfix;
	//2 column
	@include media($medium-screen-up) {
		@include span-columns(8);
 		@include shift(4);
 		float:right; 
 		margin:0px;
	}	
	@include media($large-screen-up) {
		@include span-columns(9);
 		@include shift(3);
 		float:right; 
 		margin:0px;
	}
}

.c-w1{
	// padding-bottom:30px;
	// @include media($medium-screen-up) {
	// 	padding-bottom:75px;
	// }
}

/* Subnav appears after content-body in markup, 
	but is shifted to the left */

.sidebar{
	display:none;
	//2 column
	@include media($medium-screen-up) {
		display: block;
		@include span-columns(4);
		//@include shift(-8);
	}
	@include media($large-screen-up) {
		@include span-columns(3);
		//@include shift(-8);
	}
}

#subnav {
	
	margin:-105px 0 0 0;
	border:2px solid #fff;
	background:$c-limeade;
	padding-bottom:15px;
	

	.subnav-title{
		display: flex;
		flex-direction: column;
    	justify-content: center;
		min-height: 105px;
		padding:10px 15px;
		vertical-align: middle;
		font-weight:600;
		font-size:35px;
		color:#fff;
		line-height: 1em;
		background:$c-fern;
		border-bottom:2px solid #fff;
	}

	ul li{
		a{
			display: block;
			background:$c-gray;
			color:#fff;
			line-height: 1.1em;
			padding:16px 15px 16px 25px;
			border-bottom:2px solid #fff;
			transition:all .25s;

			@include media($large-screen-up) {
				padding-left:40px;
			}

			&:hover{
				background:$c-blue; 
				text-decoration: none;
			}
		}


		&.current_page_parent > a,
		&.current_page_item > a,
		&.current_page_ancestor > a{
			background:#fff;
			position: relative;
			color:$c-gray;

			&:before{
				content:'';
				display: block;
				width:12px;
				height:12px;
				background:$c-limeade;
				border-radius:50%;
				position: absolute;
				left:8px;
				// top:17px;
				top: 50%;
    			margin-top: -6px;
				@include media($large-screen-up) {
					left:20px;
				}
			}
		} 

		.children{ 
			display: none;
			padding-left:1em;
			background:#fff;

			li{
				a{
					background:#fff;
					color:$c-gray;
					padding-top:10px;
					padding-bottom: 10px;

					&:hover{
						color:$c-limeade;
					}
				}

				&.current_page_item a:before{
					content:"-";
					background:none;
					border-radius:0;
					color:$c-limeade;
					line-height: 12px;
    				font-size: 27px;
				}
			}
		}

		&.current_page_item > .children,
		&.current_page_parent > .children,
		&.current_page_ancestor > .children{
			display: block;
		}
	}
}

/*doc
---
title: Content Row
name: content_row
category: content_classes
---

<div class="content-row">
<iframe src="https://www.youtube.com/embed/eOq-2MaskSI" width="400" height="225" frameborder="0" allowfullscreen="allowfullscreen"></iframe><br>
<iframe src="https://www.youtube.com/embed/mCP9dcS-ITk" width="400" height="225" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
</div>

*/

.content-row{
	@include media($medium-screen-up) {
		display:flex;
		justify-content:space-between;
		align-items:flex-start;
	}

	& > *{
			
		margin-bottom: 20px;
		@include media($medium-screen-up) {
			max-width: 48%;
		}
	}

}

#ctas {
	
	@include span-columns(3);
	
	@include media($large-screen-down) {
		@include span-columns(4);
		margin:0px;
	}
	@include media($medium-screen-down) {
		@include span-columns(12);
	}	
}


//Fix for font sizer
.social-block .section-inner,
#footer .f-w1.section-inner,
body:not(.home) .m-w1,
.m-w1 .section-inner{ 
	@media all and (min-width:768px) and (max-width:1350px){
		padding-right:80px !important;
	}
}