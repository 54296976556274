// Typography
$base-font-family: 'Nunito Sans', "Arial", sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1.6rem;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
// First Four make up color palette. Add more as required 
// Handy naming tool : http://chir.ag/projects/name-that-color 
$c-cornflower: #003797;
$c-fern: #4e822a;
$c-limeade:#6dae00;
$c-gray: #4b555a;
$c-blue:#003797;

$prim-color: $c-cornflower;
$sec-color: $c-fern;
$prim-color-2: #999;
$sec-color-2: $c-gray;
  
// Font Colors
$base-font-color: #4b555a;
$action-color: $prim-color;

//Typography Colors
$first-heading-color: #000;
$second-heading-color: $c-fern;

// Border
$base-border-color: $sec-color-2;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);
$tertiary-background-color:violet;

// Other Background Colors
$first-alternative-background-color:limegreen;
$second-alternative-background-color:crimson;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

//Tab Colors
$tabActive:$prim-color;
$tabBorder:$prim-color;

//Accordion Colors
$accordion-color:$prim-color;


// Animations
$base-duration: 150ms;
$base-timing: ease;


//Add colors to styleguide
/*doc
---
title: Background Colors
name: background
category: Base CSS
---

We have a few background colors that can be used in various contexts.
These are not for use as the entire page background but instead for
specific components and modules on the page.

<div class="line txtC">
    <div class="col cols6 ">
      <div class="docSwatch primary-color"></div>
      <code>primary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch secondary-color"></div>
      <code>secondary-color</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch accent-color"></div>
      <code>accent-color</code>
    </div>
    <div class="col cols6 lastCol">
      <div class="docSwatch  sec-accent-color"></div>
      <code>sec-accent-color</code>
    </div>
</div>
*/
.primary-color{background:$prim-color;}

.secondary-color{background:$sec-color;}

.accent-color{background:$prim-color-2;} 

.sec-accent-color{background:$sec-color-2;} 


