@media print { 
	*,
	*:before,
	*:after { 
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	.c-w1 a {
		text-decoration: underline;

		&:visited {
			text-decoration: underline;
		}

		&[href]:after {
			content: " (" attr(href) ")";
		}

		&[href^="#"]:after,
		&[href^="javascript:"]:after {
			content: "";
		}

	}
	
	abbr[title]:after {
		content: " (" attr(title) ")";
	}
	
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	} 

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
		height: auto;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2, h3 {
		page-break-after: avoid;
	}

	//Custom GSSI

	#ctas,
	#subnav,
	#mainnav,
	#header-banner,
	#util,
	#bottomlinks,
	#credit,
	#hero-banner {
		display: none;
	}

	#wrapper {
		width: 7in;
		padding-top: .5in;
		position: relative;
	}

	#header {
		position: static !important;
		left: 0;
		top: 0;
		height: 1in;
	}

	#search-toggle,
	#secondary-nav,
	#mainnav,
	#menu-toggle,
	.menu-wrap,
	.font-sizer-wrap,
	.f-w1,
	#footer-logo,
	.browse-options,
	.blog-posts-header,
	.blog-posts-footer{
		display: none !important;
	} 

	#footer {
		margin-top: 0.5in;
	}

	div.f-w2{
		border:none;
	}

	.accordion .inner{
		max-height: none;
	}

	.news-list,
	.section-icon-list ul.iconlink-list,
	.overview-list,
	.blog-posts > ul{
		display: flex;
		flex-wrap:wrap; 
	}


	/*** home ***/
	.section-projects ul.project-list .slick-track{
		width:auto !important;
		transform: none !important;
		display: flex;
    	flex-wrap: wrap;
	}

	.section-projects ul.project-list .slick-slide{
		opacity:1 !important; 
		width:44% !important;
	}


	/*** blog ****/
	.blog-posts > ul > li{
		width:50% !important;
	}

	.blog .blog-posts>ul>li .post-cats, .archive .blog-posts>ul>li .post-cats, .category .blog-posts>ul>li .post-cats{
		position: static !important;
	}

	.blog .blog-posts>ul>li.featured .content-wrap, 
	.archive .blog-posts>ul>li.featured .content-wrap, 
	.category .blog-posts>ul>li.featured .content-wrap{
		display: block !important;
	}

	/*** map ***/
	.wpgmza_map,
	.wpgmza_sl_main_div{
		display: none !important;
	}
 
}