/*******************************************
Banner
*******************************************/

#hero-banner{
	position: relative;
	@include fillScreen;
	@include media($medium-screen-up){
		height:280px;
		overflow: hidden;
	}
	

	.banner{
		@include media($medium-screen-up){
			position: absolute;
			top:0;
			left:50%;
			margin-left:-960px;
			width:1920px;
			max-width:none;
		}
	}
}