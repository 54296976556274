/*******************************************
Map
********************************************/

/*hide radius values*/
#content .wpgmza_sl_radius_div{
	display:none;
}

.wpgmza-basic-listing-image-holder{
	display: none;
}


.wpgmza_sl_main_div{
	margin-bottom:12px;
	position:relative;
	
	.wpgmza_sl_query_div{
		width:100%;
		padding:0;
		height: auto;

		@include media($large-screen-up){
			padding:0 200px 0 305px;
		}

		.wpgmza_sl_query_innerdiv1{
			font-weight: 700;
			font-size: 20px;
			color: $c-blue;
			width:auto;

			@include media($large-screen-up){
				position:absolute;
				top:4px;
				left:0;
				font-size: 26px;
			}
		}
		.wpgmza_sl_query_innerdiv2{
			float: none;

			@include media($large-screen-down){
				padding:0 142px 0 0;
			}
			input{
				float: none;
				width:100% !important;
				display: block;
				border-right:0;
				font-family: 'Asap', sans-serif;
				font-size: 18px;
				color: #4b555a;
				padding: 5px 15px;
				height: 40px;
				box-shadow:none;
				margin:0;

				@include media($large-screen-up){
					font-size: 20px;
					height: 50px;
					padding: 5px 20px;
				}
			}
		}
	}
	.wpgmza_sl_reset_button_1{
		height: 40px;
		line-height: 40px;
		padding: 0 15px;
		font-size: 18px;
		position:absolute;
		right:0;
		bottom:0;

		@include media($large-screen-up){
			width:120px;
			bottom:auto;
			top:0;
			font-size: 20px;
			line-height: 50px;
			height: 50px;
		}
	}
	.wpgmza_sl_search_button_1{
		width:40px;
		height: 40px;
		padding:0;
		font-size: 0;
		background-image: url(../img/icons/icon-search-bold.png);
		background-position:center;
		background-repeat:no-repeat;
		position:absolute;
		bottom:0;
		right:95px;

		@include media($large-screen-up){
			right:146px;
			top:0;
			bottom:auto;
			width:50px;
			height: 50px;
		}
	}
	&+div #locationSelect{
		display: none;
	}
}

#wrapper{
	.wpgmza_map{
		border-top:10px solid $c-limeade;
		border-bottom:10px solid $c-blue;

		@include media($medium-screen-down){
			height: 500px !important;
		}
		@include media($small-screen-down){
			height: 400px !important;
		}
	}
	.gm-style .gm-style-iw{
		//top:0 !important;
		//left:0 !important;
		//height: auto !important;
		//border-top:10px solid $c-blue;
		//padding: 15px 30px 5px 20px;
		//width:360px !important;
		padding: 9px 20px 12px 7px;
		font-family: $base-font-family;

		&>div{
			width:100% !important;
			max-width: 100% !important;
		}

		.wpgmza_markerbox{
			p{
				font-size: 14px;
				color: #4b555a !important;
				line-height: 1.42857;
				margin:0;
				padding:0;

				&.wpgmza_infowindow_title{
					font-weight: 600;
					font-size: 18px;
					color: #76b800 !important;
				}
			}
			.wpgmza_gd{
				font-size: 14px;
				color: $c-blue !important;
				min-height: 24px;
				display: inline-block;
				margin-top:8px;
				padding-top:2px;
				background:url(../img/icons/icon-compass.png) no-repeat left top;
				padding-left:30px;
			}
		}

		&+div{
			width:22px !important;
			height: 22px !important;
			border-radius:50%;
			top:16px !important;
			right:10px !important;
			border:2px solid $c-blue;
			opacity: 1 !important;

			img{
				display: none;
			}

			&:before, &:after{
				content:'';
				width:12px;
				height: 2px;
				background:$c-blue;
				position:absolute;
				left:3px;
				top:8px;
				transform-origin: center;
			}
			&:before{
				transform: rotate(-45deg);
			}
			&:after{
				transform: rotate(45deg);
			}
		}
	}

	.wpgmza_marker_list_class{
		clear: both;
		position:relative;
		min-height: 90px;

		@include media($small-screen-up){
			min-height: 65px;
		}
	}
	.no-result-msg{
		position:relative;
		clear: both;
		z-index: 1;

		p{
			position:absolute;
			left:0;
			top:15px;
			font-size: 18px;
			line-height: 1.2;
		}
	}

	.wpgmza_basic_row{
		display: block;
		border: 0;
		position:relative;
		background:#fff;
		border-bottom:1px solid $c-blue;
		padding: 20px 24px 24px 15px;
		margin: 0;
		z-index: 2;
		.wpgmza-basic-listing-content-holder{
			float: none;
			position:relative;
		}
		.wpgmza-content-address-holder{
			width:100%;
			float: none;
			padding: 32px 0 0 0;

			@include media($medium-screen-up){
				padding: 55px 0 0 0;
			}
			@include media($large-screen-up){
				padding: 0 182px 0 224px;
			}

			p{
				margin:0;
			}

			&>p:first-child{
				position:absolute;
				left:35px;
				top:4px;

				@include media($medium-screen-up){
					left:48px;
					top:18px;
					max-width: 200px;
				}
				@include media($large-screen-up){
					max-width: 150px;
				}

				a{
					font-weight: 600;
					font-size: 20px;
				}
			}
			
			&>img{
				position:absolute;
				left:0;
				top:0;
				max-width: 25px !important;
				margin:0 !important;
				height: auto;

				@include media($medium-screen-up){
					max-width: 42px !important;
				}
			}
			.wpgmza-address, .wpgmza-desc{
				font-style: normal;
				font-size: 15px;
				line-height: 1.46666;
			}

			.wpgmza-desc{
				&>p:nth-child(2){
					br{display:none;}
				}
			}
			.wpgmza_gd{
				
				font-size: 16px;
				color: $c-blue !important;
				min-height: 16px;
				display: inline-block;
				background-image:url(../img/icons/icon-compass.png);
				background-repeat:no-repeat;
				background-position:left center;
				background-size:16px auto;
				padding-left:22px;
				margin-top:15px;

				@include media($medium-screen-up){
					position:absolute;
					right:0;
					top:20px;
					margin-top:0;
					min-height: 24px;
					background-size:24px auto;
					background-position:left top;
					padding-left:30px;
				}
			}
		}
	}

	.wpgmaps_directions_outer_div{
		margin-top:30px;

		table{
			table-layout:auto;

			input, select{
				margin-bottom:0;
			}
			.wpgmaps_get_directions{
				padding: 0 20px;
				height: 40px;
				line-height: 40px;

				@include media($medium-screen-up){
					height: 50px;
					line-height: 50px;
				}
			}
		}
	}
}

.no-locations{
	padding:20px 0;
}


.page-id-28709{
	.wpgmza_sl_main_div, .wpgmza_map, a[name*='marker'], .wpgmza-basic-listing-image-holder, .wpgmza-content-address-holder img, a.wpgmza_gd{
		display: none !important;
	}
	#wrapper .wpgmza_basic_row .wpgmza-content-address-holder>p:first-child{
		left:0;
	}
	#wrapper .wpgmza_basic_row .wpgmza-content-address-holder{
		padding-right:0;
	}
	a[id*='wpgmaps_marker']{
		pointer-events: none;
		text-decoration: none;
	}
}


#directions_panel_1{
	table{
		table-layout: auto;

		td{
			border:0;
		}
	}
}