/*******************************************
Footer
********************************************/

#footer { 
	@include fillScreen;
	clear:both;
}

.footer-cta{
	@include sidePad;
	background:$c-blue;
	color:#fff;
	text-align:center;
	padding-top:20px;
	margin-bottom: 45px;/*equal to margin on cta ul*/
	@include media($medium-screen-up) {
		padding-top:50px;
	}

	.cta-content{
		padding:0 15px;
		margin-bottom: 25px;
		@include media($medium-screen-up) {
			margin-bottom: 50px;
		}


		h2{
			color:#fff;
			font-weight: 800;
			font-size:21px;
			margin-bottom: 0.25em;
			@include media($medium-screen-up) {
				font-size:44px;
			}
		}

		p{
			font-size:15px;
			@include media($medium-screen-up) {
				font-size:24px;
			}
		}

		a{
			color:#fff;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}

	ul{
		margin-bottom: -45px;/*equal to margin on cta holder*/
		@include media($medium-screen-up) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		li{
			margin-bottom: 8px;
			@include media($medium-screen-up) {
				width:32%;
			} 
			a{
				display: block;
				text-transform: uppercase;
				color:$c-blue;
				font-size:20px;
				font-weight: 800;
				line-height: 1em;
				text-decoration: none;
				background:#fff;
				padding:27px 15px;
				border:2px solid $c-limeade;
				position: relative;
				transition:all .25s;
				@include media($medium-screen-up) {
					font-size:24px;
					padding:35px 15px;
				}

				&:after{
					content:'';
					display: block;
					border-bottom:1px solid #99afd5;
					border-top:1px solid #6687c1;
					width:90px;
					clear:both;
					margin:5px auto 0;
					transition:all .25s;
				}

				&:hover,
				&:focus{
					background:$c-fern;
					color:#fff;

					&:after{
						width:70%;
						border-color:#fff;
					}
				}

			}
		}
	}
}

.f-w1{
	padding:10px 15px 25px;
	display: flex;
	flex-direction:column;


	@include media($medium-screen-up) {
		display: block;
		padding-top:65px;
		padding-bottom:50px;
	}
}

.top-row{
	order:2;
	@include media($medium-screen-up) {
		display:flex;
		 align-items: flex-start;
		justify-content:space-between;
	}
}

.newsletter{
	padding-top:12px;
	@include media($medium-screen-up) {
		padding-top:0;
		display:flex;
	    align-items: flex-start;
	    justify-content:space-between;
	    flex:1;
	    padding-right: 25px;
	}


    .news-descrip{
    	padding-right: 15px;
    	@include media($medium-screen-up) {
			width:calc(100% - 265px);
		}
    }

	h2{
		font-weight: 800;
		text-transform: uppercase;
		font-size:25px;
		@include media($medium-screen-up) {
			font-size:30px;
		}
	}
	p{
		font-size:15px;
		@include media($medium-screen-up) {
			font-size:18px;
		}
	}

	.btn{
		text-transform: uppercase;
		color:#fff;
		padding:14px 15px;
		font-size:17px;
		@include media($medium-screen-up) {
			padding:20px;
			font-size:20px;
			white-space: normal;
			width:265px;
		}

	}
}

.footer-social .social-links{
	padding-left:0;
	padding-right:0;
	text-align:left;
	@include media($medium-screen-up) {
		padding:0;
		padding-right:5px;
	}

	li{
		@include media($medium-screen-up) {
			margin:0 5px 0 0;
		}

		a{
			.fa{
				font-size:25px;
				@include media($large-screen-up) {
					font-size:35px;
				}
			}
		}
	}
}

#bottomlinks{
	order:1;
	
	@include media($medium-screen-up) {
		border-top:2px solid $c-fern;
		padding:20px 0;
	}
	.menu{
		& > li{
			border-bottom:1px solid #eaf2f4;

			&:last-child{
				border-bottom-width:4px;
				border-color:$c-limeade;
			}
			@include media($medium-screen-up) {
				border-bottom:none;
				float:left;
				width:25%;
				padding-right:15px;
				&:nth-child(5n){ 
					clear:left;
				}
				
			}
			@include media($large-screen-up) {
				width:14%;
				&:nth-child(5n){
					clear:none;
				}

			}
			& > a{
				display: block;
				text-transform:uppercase;
				font-size:15px;
				font-weight: 800;
				padding:8px 0;
				color:$c-fern;
				@include media($medium-screen-up) {
					font-size:14px;
				}
				&:hover{
					text-decoration: none;
				}
			}
		}
	}

	.sub-menu{
		display: none;
		@include media($medium-screen-up) {
			display: block;
		}

		li{
			position: relative;
			padding-left:12px;
			margin-bottom: 5px;
			&:before{
				content:'';
				display: block;
				width:5px;
				height: 5px;
				background:$c-blue;
				border-radius: 50%;
				position: absolute;
				top:10px;
				left:0; 
			}

			/*hide overview links for accessibility*/
			//&:first-child{display:none;}

			a{
				color:$c-blue;
				font-size:14px;
				text-decoration: underline;
				line-height: 1.5em;
				display: block;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}

	.d-hide{
		@include media($medium-screen-up) {
			display: none;
		}
	}
}

.f-w2{
	padding:15px 33px;
	background:#edf3f5;
	border-top:18px solid $c-limeade;
	font-size:12px;
	@include media($medium-screen-up) {
		@include sidePad;
		border-top-width:20px;
		font-size:13px;
		padding-top:25px;
		padding-bottom:35px;
	}

	#footer-logo{
		@include media($medium-screen-down) {
			display: none;
		}
	}


	a{
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		} 
	}

	.f-col{
		@include media($medium-screen-up) {
			@include span-columns(2);
		} 
	}

	.f-col.footer-e-contact{
		@include media($medium-screen-down) {
			padding:12px 0;
		}

	}

	.f-col.credit{
		@include media($medium-screen-up) {
			@include span-columns(4);
		}
		p{
			font-size:13px;
			margin-bottom: 3px;
		}
	}

	// #gs-credit{
	// 	a{
	// 		text-decoration: none;
	// 		color:#4b555a;
	// 	}
	// }
}