
fieldset {
  background-color: $secondary-background-color;
  border: $base-border;
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

#{$all-text-inputs},
select[multiple=multiple], select {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $small-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;

  + label {
    display: inline-block;
  }
}

input[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}

.gform_confirmation_message{
      font-size: 20px;
      color: #4e822a;
      font-weight: 600;
}

.gform_wrapper{
  li.gfield{
    padding-left:0;
    &:before{
      display: none;
    }
  }
}


/*doc
---
title: Forms
name: forms
category: Forms
---

Basic Form Styles. If in Wordpress, use to override Gravity Forms default styling.

<form>
          <div class='table-row'>
            <label class='center' for='field1'>Full Name</label>
            <div class='input-holder'>
              <input type='text' id='field1' name='field1' />
            </div>
          </div>
          <div class='table-row'>
            <label class='center' for='field2'>Email</label>
            <div class='input-holder'>
              <input type='text' id='field2' name='field2' />
            </div>
          </div>
          <div class='table-row'>
            <label class='center' for='field3'>Phone Number</label>
            <div class='input-holder'>
              <input type='text' id='field3' name='field3' />
            </div>
          </div>
          <div class='table-row'>
            <legend>Select a Choice</legend>
            
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_0' name='Field5' />
                <label for="Field5_0">First Choice</label>
              </div>
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_1' name='Field5' />
                <label for="Field5_1">Second Choice</label>
              </div>
              <div class='input-holder'>
                <input type="radio" value='First Choice' id='Field5_2' name='Field5' />
                <label for="Field5_2">Third Choice</label>
              </div>
            
          </div>

          <div class='table-row'>
            <legend>Check all that apply</legend>
            <div class='input-holder'>
              <input type='checkbox' value='First Choice' id='Field6' name='Field6' />
              <label for="Field6">First Choice</label>
            </div>
            <div class='input-holder'>
              <input type='checkbox' value='Second Choice' id='Field7' name='Field7' />
              <label for="Field7">Second Choice</label>
            </div>
            <div class='input-holder'>
              <input type='checkbox' value='Third Choice' id='Field8' name='Field8' />
              <label for="Field8">Third Choice</label>
            </div>
          </div>
          <div class=='table-row'>
            <label for='Field9' class='center'>Select a Choice</label>
            <div class='input-holder'>
              <select id='Field9' name='Field9'>
                <option value='First Choice'>Option 1</option>
                <option value='Second Choice'>Option 2</option>
                <option value='Third Choice'>Option 3</option>
                <option value='Fourth Choice'>Option 4</option>
                <option value='Fifth Choice'>Option 5</option>
              </select>
            </div>
          </div>
          <input type='submit' value='Submit Form' />
        </form>

*/
