/*******************************************
Breadcrumbs
*******************************************/

.breadcrumbs{
	padding:10px 0 15px 0;
	font-size:1.5rem;
	color:#333;

	&>span{
		padding:0 6px;
		&:first-child{
			padding-left:0;
		}
	}

	a{
		color:#666;
	}
}