/*************************************
Search
**************************************/


.search{
	// #subnav{
	// 	display: none;
	// }

	// #content{
	// 	width:100%;
	// 	float:none;
	// 	left:0;
	// 	padding-top:30px;
	// }
	#content{
		padding-top:30px;
	}

	.breadcrumbs{
		display: none;
	}

	.c-w1 #searchform{
		max-width: 500px;
		margin-bottom: 40px;
		display: flex;

		input[type='text']{
			margin:0;
			width:calc(100% - 100px);

			@include media($medium-screen-up){
				width:calc(100% - 125px);
			}
		}

		input[type="submit"]{
			width:100px;
			padding: 20px;
			@include media($medium-screen-up){
				width:125px;
			}
		}
	}
}

.popular-searches{
	margin:2em 0;

	.title{
		margin-bottom: 1em;
	}

	ul{
		margin:0;
		
		li{
			text-transform: capitalize;
		}
	}
}



.search-list{
	
	.search-result{
		padding:20px;
		//width:48%;
		border:1px solid #ccc;
		margin-bottom:20px;
	}

	.entry-title{
		font-size:2.5rem;
		margin-bottom: 0.5em;
		a{
			color:inherit;
			text-decoration: none;
		}
	}

	.breadcrumbs{
		display: block;

		.breadcrumb{
			padding:0;

			&:before{
				content:'/';
				padding-left:5px;
				padding-right:5px
			}
		
			&:first-child:before{
				display: none;
			}
		}


	}

	.entry-summary{
		margin-bottom: 1em;
	}

	.taxonomy-list{
		display: flex;
		align-items:flex-start;
		justify-content: flex-start;

		.title{
			margin:0;
		}

		ul{
			margin:0;
			display: flex;
			align-items:flex-start;
			justify-content: space-between;

			li{
				&:before{
					display: none;
				}

				&.selected{
					font-weight: 700;
				}
			}
		}
	}
}
