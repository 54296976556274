/*******************************************
Header
********************************************/


#header{
	@include fillScreen;
	@include sidePad;
	position: relative;
	background:#fff;
	z-index: 200; 
	box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.49);
	//@include media($medium-screen-up){
		position: sticky;
	    top: 0;
	    left: 0;
	//}
}

.h-w1{
	@include fillScreen;
	background:$c-cornflower;
	max-height:200px;
	transition:all .35s;	
}


.social-links{
	text-align:center;
	padding:15px 0;
	@include sidePad;
	background:#fff;
	li{ 
		display: inline-block;
		margin:0 5px;
		&:first-child{margin-left:0;}
		&:last-child{margin-right:0;}

		a{
			display: block;
			width:45px;
			height:45px;
			background:#000;
			padding:12px;
			transform:translateY(0);
			transition:all .25s;
			text-align: center;

			@include media($large-screen-up){
				width:60px;
				height:60px;
			}

			.fa{
				font-size: 22px;
			    text-align: center;
			    color: #fff;
			}

			&.util-Twitter{background:#1da1f2;}
			&.util-Facebook{background:#003797;}
			&.util-Flickr{background:#979797;}
			&.util-Youtube{background:#c80000;}


			svg{
			  max-width: 100%;
			  height:100%;
			  fill:white;
			}

			&:hover,
			&:focus{
				transform:translateY(-3px); 

			}
		}
	}
}

#header .social-links{
	@include media($medium-screen-up){
		display: none;
	}
}

#logo{
	display:inline-block;
	max-width:100px;
	margin-top:10px;
	transition:all .25s;
	@include media($medium-screen-up){
		max-width: 300px;
		margin-top:0;
	}
	@media screen and (min-width:768px) and (max-width:1400px){
		max-width: 180px;
	}
}

#search-toggle{
	position:absolute;
	top:15px;
	//bottom:71px;
	right:66px;
	overflow:hidden;
	height:1px;
	width:50px;
	height:50px;
	line-height:50px;
	// padding-top:50px;
	padding:0;
	border-radius:0;
	z-index: 100;
	//background:$c-cornflower url(../img/icons/icon-search.png) center center no-repeat;
	background-size:cover;
	@include media($medium-screen-up) {
		// top:91px;
		top:auto;
		bottom:54px;
    	right: 10px;
    	width:70px;
    	height:70px;
    	line-height: 70px;
    	//padding-top:70px;
	}
	@media screen and (min-width:768px) and (max-width:1400px){
		bottom:31px;
	}
	@include media($ex-large-screen-up) {
		display:none;
	}

	span{
		@include elementInvisible;
	}

	.fa{
		color:#fff;
		font-size: 20px;
    	display: block;
    	@include media($medium-screen-up) {
			font-size: 28px;
    	}

		&.fa-close{
			display: none;
		}
	}

	&.open{
		@include media($medium-screen-up) {
			transform:translateY(-120px);/* minus height of search box*/
		}

		.fa-close{
			display: block;
		}

		.fa-search{
			display: none;
		}
	}
}



.sticky{
	#search-toggle{
		@include media($medium-screen-up) {
			width: 56px;
		    height: 56px;
		    line-height: 56px;
		    bottom: 26px;
		}
	}

	&.home{
		bottom:17px;
		@include media($medium-screen-up) {
			bottom:45px;
		}
	}
}



.search-form{
	position: relative;
	overflow:hidden;
	max-height:0;
	//transition:max-height .35s;
	background: rgba(0,0,0,0.7);
	margin: 0 -10px;
	@include media($ex-large-screen-up){
		position: absolute;
		top:33px;
		right:3px;
		max-height: none;
		background:none;
		margin:0;
	}
	@media screen and (min-width:1200px) and (max-width:1400px){
		top:22px;
	}

	input[type='text']{
		display: inline-block;
		vertical-align: middle;
		width:100%;
		width:calc(100% - 45px);
		height: 40px;
		margin:0;
		padding:0 25px;
		font-size:1.6rem;
		font-size:16px;
		
		@include media($medium-screen-up){
			font-size:2.0rem;
			font-size:20px;
			height: 80px;
			width:calc(100% - 85px);

		}

		@include media($ex-large-screen-up){
			width:310px;
		}		
	}

	input[type='submit']{
		display: inline-block;
		vertical-align: middle;
		overflow: hidden;
		height:1px;
		width:40px;
		padding:39px 0 0 0;
		margin:0;
		border-radius:0;
		background:url(../img/icons/icon-search-large.png) 0 0 no-repeat;
		background-size:100% auto;
		transition:all .25s;
		@include media($medium-screen-up){
			width:80px;
			padding-top:79px;
		}

		&:hover{
			opacity:.8;
		}
	}

	&.search-open{
		max-height:120px;
	    height:120px;
	    z-index: 999;
	    padding: 20px; 
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
	}
}

