/**************************
News
***************************/
.blog, .archive, .category{
	#subnav{
		position:absolute;
		bottom:calc(100% - 19px);
		left:0;

		ul{
			display: none;
		}
	}
	#content{
		float: none;
		margin:0;
		width:100%;
	}
	.breadcrumbs{
		@include media($medium-screen-up){
			margin-left:26%;
		}
	}

	.page-title-row{
		position:relative;
		h1{
			border-bottom:1px solid #1d2123;
			color: #1d2123;
			padding-bottom:8px;
			@include media($medium-screen-up){
				font-size:5rem;
			}
		}
		.browse-options{

			@include media($ex-large-screen-up){
				position:absolute;
				bottom:9px;
				right:0;
			}

			&>*{
				@include media($medium-screen-up){
					display: inline-block;
					vertical-align: middle;
				}
			}

			&>span{
				color: $prim-color;
				font-size: 20px;
				font-weight: 700;
				margin-right:15px;
				@include media($medium-screen-down){
					display: block;
					margin-bottom:5px;
				}
				@include media($small-screen-up){
					font-size: 26px;
				}
			}

			&>div{
				margin-bottom:10px;
				position:relative;
				@include media($ex-small-screen-up){
					width:48%;
					float: left;
					margin-bottom:20px;
				}
				@include media($medium-screen-up){
					min-width: 235px;
					float: none;
					width:auto;
					margin:0;
				}
				@include media($large-screen-up){
					min-width: 270px;
				}

				&:before{
					font-family: 'icomoon' !important;
			        speak: none;
			        font-style: normal;
			        font-weight: normal;
			        font-variant: normal;
			        text-transform: none;
			        line-height: 40px;
			        content:'\e902';
				    top: 0;
				    right: 0;
				    font-size: 17px;
				    background: $prim-color;
				    padding: 0;
				    text-align: center;
				    width:40px;
				    height: 40px;
				    position:absolute;
				    color: #fff;
				}
			}

			.categories{
				@include media($ex-small-screen-up){
					margin-right:4%;
				}
				@include media($medium-screen-up){
					margin-right:26px;
				}
			}

			select{
		    	appearance: none;
		    	position:relative;
				padding: 0 48px 0 15px;
				width:100%;
				height: 40px;
				line-height: 40px;
				border:1px solid #4b555a;
				border-radius:0;
				margin:0;
				box-shadow:none;
				font-size: 16px;
				font-family: 'Asap', sans-serif;
				color: #4b555a;
				background:transparent;

				@include media($large-screen-up) {
					height: 38px;
					line-height: 38px;
					padding: 0 40px 0 15px;
					width:auto;
				}
			}

			.chosen-container{
				font-size: 20px;
				display: block;
				font-family: 'Asap', sans-serif;

				.chosen-single{
					background-color:#fff;
					border:1px solid #4b555a;
					color: #4b555a;
					line-height: 50px;
					height: 50px;
					padding: 0 0 0 18px;
					overflow: visible;
					text-decoration: none;

					span{
						margin-right: 68px;
					}

					div{
						width:55px;
						height: 50px;
						padding-left:4px;
						background:#fff;
						margin:-1px -1px 0 0;
					}

					.b{
						color: #fff;
						background:$prim-color;

						&:before{
							font-size: 26px;
							line-height: 50px;
						}
					}
				}
				.chosen-drop{
					margin-top:0;
				}
				.chosen-results{
					li{
						padding: 8px 15px;
					}
				}
			}
		}
	}

	.blog-posts-header{
		margin:30px 0 15px 0;
		@include media($ex-small-screen-up){
			margin:35px 0 10px 0;
		}
		&:after{
			content:'';
			display: table;
			clear:both;
		}
	}

	.filter-options{
		@include media($medium-screen-up){
			float: left;
			&>*{
				display: inline-block;
				vertical-align: middle;
			}
		}
		&>span{
			color: $prim-color;
			font-size: 20px;
			font-weight: 700;
			margin-right:5px;
			@include media($medium-screen-down){
				display: block;
				margin-bottom:5px;
			}
			@include media($small-screen-up){
				font-size: 26px;
			}
			@include media($large-screen-up){
				margin-right:15px;
			}
		}
		.tags{
			@include media($medium-screen-down){
				margin-bottom:20px;
			}
			ul{
				margin:0;
				&:after{
					content:'';
					display: table;
					clear: both;
				}
			}
			li{
				float: left;
				margin:0;
				padding:0;

				@include media($large-screen-up){
					margin:0 18px 0 0;
				}

				&:before{
					display: none;
				}
			}
			a{
				color: rgba(75,85,90,0.5);
				font-size: 20px;
				font-family: 'Asap', sans-serif;
				text-decoration: none;
				display: inline-block;
				padding: 4px 20px;
				border:1px solid transparent;
				transition: all 0.2s ease;

				@include media($small-screen-up){
					&:hover{
						border-color:#bac2c6;
					}
				}

				&.active{
					border-color:#bac2c6;
					color: rgba(75,85,90,1);
				}
			}
			.news{
				a{
					color: rgba(0,55,151,0.5);

					&:before{
						content:'';
						width:14px;
						height: 14px;
						display: inline-block;
						background:$prim-color;
						margin-right:3px;
					}

					&.active{
						color: rgba(0,55,151,1);
					}
				}
			}
			.stories{
				a{
					color: rgba(78,130,42,0.5);

					&:before{
						content:'';
						width:14px;
						height: 14px;
						display: inline-block;
						background:$sec-color;
						margin-right:3px;
					}

					&.active{
						color: rgba(78,130,42,1);
					}
				}
			}
		}
	}

	.blog-posts{
		clear: both;
		&>ul{
			margin:0 -10px;
			padding:0;
			display: flex;
			flex-wrap: wrap;

			@include media($small-screen-up){
				margin:0 -15px;
			}


			&>li{
				padding: 10px;
				margin:0;
				width:100%;
				display: flex;

				@include media($small-screen-up){
					padding: 15px;
					width:50%;
				}
				@include media($medium-screen-up){
					width:33.333333%;
				}

				&:before{display:none;}

				.inner{
					border:1px solid #4b555a;
					width:100%;
					padding: 9px;
					display: flex;
				}

				a{
					text-decoration: none;
				}

				.content-wrap{
					position:relative;
					padding: 2px 6px 45px 6px;
					width: 100%;
					border-top:14px solid transparent;

					// &:before{
					// 	content:'';
					// 	display: block;
					// 	height: 14px;
					// 	margin:0 -6px 2px;
					// }
				}

				.post-img{
					margin:0 -6px 6px;
					img{
						width:100%;
						height: auto;
					}
				}
				.date{
					font-weight: 600;
					font-size:16px;
					margin-bottom:8px;
					display: block;
				}

				h2{
					font-size: 18px;
					line-height: 1.2;
					margin-bottom: 10px;

					@include media($small-screen-up){
						font-size: 20px;
					}
				}

				.desc{
					p{
						font-size: 15px;
						margin:0;
					}
				}

				.post-cats{
					position:absolute;
					left:0;
					bottom:0;

					ul{
						margin:0;
						padding:0;
					}
					li{
						float: left;
						padding: 0 6px;
						border-right:1px solid #4b555a;
						line-height: 0.8;
						margin-bottom:9px;

						&:before{
							display: none;
						}
						&:last-child{
							border-right:0;
						}
					}

					a{
						font-size: 14px;

						@include media($small-screen-up){
							&:hover{
								text-decoration: underline;
							}
						}
					}
				}

				&.news{
					a{
						color: $prim-color;
					}
					.content-wrap{
						border-top-color:$prim-color;
					}
				}
				&.stories{
					a{
						color: $sec-color;
					}
					.content-wrap{
						border-top-color:$sec-color;
					}
				}


				// Featured
				&.featured{
					width:100%;

					@include media($medium-screen-up){
						width:66.666666%;
					}

					.content-wrap{
						padding:2px 0 0 0;
						background:#ebf0f2;
						display: flex;
						flex-wrap:wrap;
					}
					.cols-wrap{
						@include media($small-screen-up){
							display: flex;
							width:100%;
						}
					}
					.post-img{
						margin:0;
						overflow: hidden;

						@include media($small-screen-up){
							display: flex;
							width:46.67%;
						}

						a{
							width:100%;
							position:relative;
						}

						img{
							width:100%;
							@include media($small-screen-up){
								min-width: 100%;
								min-height: 100%;
								position:absolute;
								left:50%;
								top:50%;
								width:auto;
								max-width: none;
								transform:translate(-50%, -50%);
							}
						}
					}
					.text{
						padding: 10px 15px 45px;
						position:relative;

						@include media($small-screen-up){
							width:53.33%;
							padding: 15px 30px 45px;
						}
					}

					.date{
						margin-bottom:5px;
						@include media($small-screen-up){
							margin-bottom:10px;
						}
					}

					h2{
						font-size: 22px;

						@include media($small-screen-up){
							margin-bottom:40px;
							font-size: 30px;
							line-height: 1.06;
						}
					}
					.post-cats{
						left:9px;
						@include media($small-screen-up){
							left:24px;
						}
					}
				}
				
			}
		}
		
	}

	.blog-posts-footer{
		margin-top:18px;
		&:after{
			content:'';
			display: table;
			clear: both;
		}
	}

	#featured-post{
		display: none;
	}

	&.paged{
		.blog-posts>ul>li.featured{
			display: none;
		}
	}
}

#content .navigation{
	display: flex;
	@include media($medium-screen-up){
		float: right;
	}
	&>a, &>span{
		width:35px;
		height: 35px;
		line-height: 35px;
		border:1px solid $sec-color;
		color: #666;
		font-size: 16px;
		text-decoration: none;
		text-align: center;
		transition: all 0.2s ease;

		@include media($small-screen-up){
			width:40px;
			height: 40px;
			line-height: 40px;
			font-size: 20px;
		}

		&:not(:last-child){
			border-right:0;
		}

		&.current{
			background:$sec-color;
			color: rgba(255,255,255,1);
		}

		@include media($small-screen-up){
			&:not(.dots):hover{
				background:$sec-color;
				color: rgba(255,255,255,1);
			}
			
		}

		&.prev, &.next{
			font-size: 0;
			color: #fff;
			background:$prim-color;
			border:0;

			&:before{
				font-family: 'icomoon' !important;
		        speak: none;
		        font-style: normal;
		        font-weight: normal;
		        font-variant: normal;
		        text-transform: none;
		        line-height: 35px;
		        font-size: 22px;

		        /* Better Font Rendering =========== */
		        -webkit-font-smoothing: antialiased;
		        -moz-osx-font-smoothing: grayscale;

		        @include media($small-screen-up){
					line-height: 40px;
		        	font-size: 26px;
				}
			}

			@include media($small-screen-up){
				&:hover{
					background:$sec-color;
					color: rgba(255,255,255,1);
				}
			}
		}
		&.prev{
			margin-right:2px;
			&:before{
				content:'\e901';
			}
			&+.page-numbers{
				border-left:0;
			}
		}
		&.next{
			margin-left:2px;
			&:before{
				content:'\e900';
			}
		}
	}
}

.social-block{
	background:#ebf0f2;
	overflow:hidden;
	margin:0 -10px;
	padding: 28px 10px 40px;

	@include media($small-screen-up){
		padding: 28px 10px 50px;
	}

	.heading{
		color: #1d2123;
		font-size: 22px;
		font-weight: 700;
		padding: 6px 0;
		margin:0 0 20px;
		border-bottom:1px solid $prim-color;
		line-height: 1.2;
		font-family: $base-font-family;

		@include media($small-screen-up){
			font-size: 32px;
			margin-bottom:34px;
		}
		@include media($large-screen-up){
			font-size: 44px;
		}
	}

	ul{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin:0 -25px;

		li{
			width:100%;
			padding: 0 25px;
			display: flex;
			margin-bottom:30px;

			&:last-child{
				margin:0;
			}

			@include media($medium-screen-up){
				width:50%;
				margin:0;
			}
		}
	}

	a{
		color: #003797;
		text-decoration: underline;

		@include media($small-screen-up){
			&:hover{
				text-decoration: none;
			}
		}
	}

	.inner{
		position:relative;
		padding:0 0 0 45px;
		width:100%;

		@include media($small-screen-up){
			padding:0 0 0 70px;
		}
		@include media($medium-screen-up){
			padding:0 0 50px 70px;
		}
	}

	.sm-icon{
		position:absolute;
		left:0;
		top:-1px;
		border-radius:2px;
		overflow: hidden;


		&:before{
			display: inline-block;
		    font-family: FontAwesome;
		    font-style: normal;
		    font-weight: normal;
		    line-height: 1;
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		}

		.fa-facebook{
			color: #fff;
			font-size: 28px;
			width:30px;
			height: 30px;
			background-color:#003797;
			padding: 5px 0 0 12px;

			@include media($small-screen-up){
				font-size: 36px;
				width:40px;
				height: 40px;
				padding: 7px 0 0 17px;
			}
		}
		.fa-twitter{
			color: #1da1f2;
			font-size: 38px;

			@include media($small-screen-up){
				font-size: 50px;
			}
		}
	}

	#cff{
		.cff-author{
			display: none;
		}
		.cff-post-text{
			margin:0;
			line-height: inherit;

			.cff-expand a{
				font-size: inherit;
			}

			a{
				text-decoration: underline;

				&:hover{
					text-decoration: none;
				}
			}
		}
		.cff-item{
			border-bottom:0;
			margin-bottom:22px;
			padding:0;
		}
		
	}

	#ctf{
		.ctf-item{
			border-top:0;
			padding: 0 5px;
			margin:0 0 22px;
		}
		
	}

	.btn-social{
		color: #fff;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 800;
		padding: 8px 18px 7px 45px;
		text-decoration: none;
		position:relative;
		display: inline-block;
		font-family: "Nunito Sans","Arial",sans-serif;

		@include media($small-screen-up){
			padding: 10px 22px 9px 56px;
			font-size: 16px;
			&:hover{
				text-decoration: underline;
			}
		}
		@include media($medium-screen-up){
			position:absolute;
			bottom:0;
			margin-top:0;
		}

		&:before{
			font-family: FontAwesome;
			position:absolute;
		}

		span{display:none;}

		&.fa-facebook{
			background-color:#003797;

			&:before{
				top: 8px;
			    left: 18px;
			    font-size: 16px;

			    @include media($small-screen-up){
					font-size: 22px;
				}
			}
		}
		&.fa-twitter{
			background-color: #1da1f2;

			&:before{
				top:7px;
				left:12px;
				font-size: 17px;

				@include media($small-screen-up){
					font-size: 23px;
				}
			}
		}

	}

	.twitter{
		.sm-icon svg{
			color: #1da1f2;
			fill: #1da1f2;
		}
		.btn-social{
			background:#1da1f2;
			svg{
				width:27px;
				height: 23px;

				@include media($small-screen-up){
					width:30px;
					height: 26px;
				}
			}
		}
	}
	.facebook{
		.inner{
			@include media($medium-screen-up){
				padding-left:60px;
			}
		}
		.sm-icon{
			background-color: #003797;
			top:5px;
			width:32px;
			height: 32px;
			@include media($small-screen-up){
				width:40px;
				height: 40px;
			}
			svg{
				color: #fff;
				fill: #fff;
				padding: 0px 6px 0px 10px;
			    position: relative;
			    bottom: -4px;

			    @include media($small-screen-up){
					padding: 0px 6px 0px 13px;
				}
			}
			
		}
		.btn-social{
			background:#003797;
			svg{
				left: 15px;
			    top: 7px;
			    width: 12px;
			    height: 24px;

			    @include media($small-screen-up){
			    	top: 9px;
					width: 15px;
			    	height: 27px;
				}
			}
		}
	}
}


.single-post{
	#content .navigation{
		float: none;
		justify-content: space-between;

		&>*{
			max-width: 45%;
		}
		
	}
	#nav-above{
		padding: 10px 0 30px;
	}
	#nav-below{
		padding: 30px 0 0;
	}
}