html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body{
	overflow-y:scroll;
}

#wrapper{
	@include sidePad;
}

/*****************************
Theme Basics Settings
******************************/
.section-inner { 
	position: relative;
	//padding:0 $global-edge-padding; 
	@include outer-container;
}

.screen-reader-text,
.skip{
	 @include elementInvisible();
}



/*****************************
Global Blocks
******************************/
@import 'blocks/header'; 
@import 'blocks/navigation'; 
@import 'blocks/banner'; 
@import 'blocks/font-sizer'; 
@import 'blocks/breadcrumbs'; 
@import 'blocks/nutshell'; 
@import 'blocks/layout'; 
@import 'blocks/footer'; 
@import 'blocks/map'; 


/*****************************
Templates
******************************/
@import "templates/overview"; 
@import "templates/homepage"; 
@import "templates/search"; 
@import "templates/events"; 
@import "templates/news"; 