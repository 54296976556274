ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
 
dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}

.c-w1{
  ol{
    list-style: none;
    margin: 0.25em 0 1em 2em;
    counter-reset: section;

     li{
      position: relative;
      padding-left:25px;
      margin-bottom:.5em;
      counter-increment: section; 

      &:before{
        content: "("(counter(section)) ")"; 
        display: block;
        position: absolute;
        top:0;
        left:-3px; 
        color:$c-fern;
      }
    }

    ol{
      margin-bottom:0px;
    }
  }

  ul{
    list-style: none;
    margin: 0.25em 0 1em 2em;

    li{
      position: relative;
      padding-left:16px;
      margin-bottom:.5em;

      &:before{
        content:'';
        display: block;
        width:5px;
        height: 5px;
        background:$c-limeade;
        border-radius: 50%;
        position: absolute;
        top:10px;
        left:0;
      }
    }

    ul{
      margin-bottom:0;
    }
  }
}

/*doc
---
title: Lists
name: lists
category: Base CSS
---

These list styles are only applied in the content area. They are targeted
using the .c-w1 class.

<div class='c-w1'>
<ul>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ul>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ul>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ul>

<ol>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ol>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ol>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ol>
</div>

*/