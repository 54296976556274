/*******************************************
Nutshell
********************************************/	

.nutshell{
	color:$c-gray;
	font-size:1.2rem;
	padding:11px;
	background:#f0f3f5;
	margin-bottom:25px;
	border:7px solid $c-fern;
	@include media($medium-screen-up){
		border-width:2px;
		padding:35px;
		font-size:1.8rem;
	}
	@include media($large-screen-up){
		display:flex;
	}
 
	p{
		color:$c-gray;
	}

	h2{
		color:#000;
		font-size:1.3rem;
		font-size:2.5rem;
		margin-bottom: 0.25em;
		@include media($medium-screen-up){
			font-size:2.6rem;
			margin-right:15px;
		}
		@include media($large-screen-up){
			flex-basis:190px;
		}
	}

	.inner{
		@include media($large-screen-up){
			flex-basis: calc(100% - 190px);
		}
	}

	p:last-child{
		margin-bottom: 0;
	}
}
