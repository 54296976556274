/*************************************
Overview
**************************************/

/*doc
---
title: Overview Items
name: overview_items
category: content_classes
---



<ul class="overview-list">
	<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/community-action-employment-plan/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="http://community-living-wp.gssi.net/wp-content/uploads/2018/02/overview-CommunityActionEmploymentPlan.jpg" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>Community Action Employment Plan</h3>
			<p>The Community Action Employment Plan, launched in 2013 by CLBC and partners in community, is to create more employment opportunities for people CLBC supports. </p>
		</a>
	</li>
			<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/inclusive-housing-initiative/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>Inclusive Housing Initiative</h3>
			<p></p>
		</a>
	</li>
			<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/strategy-on-aging/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>Strategy on aging</h3>
			<p>The CLBC Strategy on Aging is a proactive response to the challenges and opportunities associated with supporting adults with developmental disabilities as they grow older. </p>
		</a>
	</li>
			<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/start-with-hi/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>Start With Hi</h3>
			<p>Start with Hi is a public awareness initiative that shows how small actions like saying "Hi" can increase the safety of people with developmental disabilities. </p>
		</a>
	</li>
			<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/i-can-be-safe-online/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="http://community-living-wp.gssi.net/wp-content/uploads/2018/02/overview-iCanBeSafeOnline.jpg" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>I Can Be Safe Online</h3>
			<p>ICanBeSafeOnline.com is Community Living BC's online safety website that helps people learn to be safe online while still having fun with their friends and family. </p>
		</a>
	</li>
			<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/community-living-month/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="http://community-living-wp.gssi.net/wp-content/uploads/2018/02/overview-CommunityLivingMonth.jpg" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>Community Living Month</h3>
			<p>Each October, B.C. marks Community Living Month to recognize the contributions and accomplishments of people with diverse abilities across our province. </p>
		</a>
	</li>
			<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/wow-awards/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>WOW Awards</h3>
			<p></p>
		</a>
	</li>
			<li>
		<a href="http://community-living-wp.gssi.net/provincial-projects/include-me-a-quality-of-life-focus/">
			<div class="thumbnail">
				<div class="thumbnail-inner"><img src="http://community-living-wp.gssi.net/wp-content/uploads/2018/02/overview-IncludeMe.jpg" alt=""></div>
				<div class="hover-block"></div> 
			</div>
			<h3>include Me! A Quality of Life focus </h3>
			<p><em>include Me!</em> invites people CLBC supports to participate in a survey measuring quality of life. Information gathered provides valuable input for CLBC and service providers. </p>
		</a>
	</li>
</ul>

*/

.c-w1 ul.overview-list{
	margin:0px;
	padding:0px;
	@include media($medium-screen-up){
		display:flex;
		flex-wrap:wrap;
		//justify-content: space-between;
	}

	li{
		display: inline-block;
		vertical-align: top;
		margin-bottom: 20px;
		padding:0;
		max-width:300px;
		margin:0 auto 20px auto;
		display: block;
		@include media($medium-screen-up){
			width:50%;
			padding-right:2%;
			margin:0 0 20px 0;
		}

		@include media($large-screen-up){
			width:33%;
			padding-right:1%;
		}

		&:before{
			display: none; 
		}

		a{
			text-decoration: none;
			display: block;
		}

		.thumbnail{
			position: relative;
			//padding-bottom:10px;
			//height:205px;
			overflow: hidden;
			margin-bottom: 10px;
			border:1px solid $c-blue;
		}

		.thumbnail-inner{
			// position: absolute;
			// left:0;
			// top:0;
			// right:0;
			// bottom:0;
			transition:all .25s;
			-webkit-backface-visibility: hidden;
		}

		img{
			width:100%;
			margin:0;
		}



		.hover-block{
			display: block;
			position: absolute;
			left:0;
			bottom:0;
			width:60px;
			height:60px;
			background:$c-blue;
			border-top:4px solid #fff;
			border-right:4px solid #fff;
			&:after{
				content:'';
				display: block;
				position: relative;
				top:0;
				width:100%;
				height: 100%;
				background:url(../img/icons/icon-arrow-down.png) center center no-repeat;
				transition:all .25s;
			}

			&:before{

			}
		}

		h2{
			color:$c-fern;
			font-size:2.0rem;
			margin-bottom: 0.25em;
			@include media($medium-screen-up){
				font-size:2.2rem;
			}
		} 

		p{
			color:$c-gray;
			font-size:1.5rem;
			line-height: 1.333em;
		}


		a{
			text-decoration: none;
			display: block;
		}

		&:hover,
		& a:focus{
			.hover-block{
				background-color:$c-fern;
				&:after{
					transform: rotate(-90deg);
					top:3px;
				}
			} 

			.thumbnail-inner{
				// left: -5%;
			 //    right: -5%;
			 //    top: -5%;
			 //    bottom: -5%;	
				transform:scale(1.1);
				transition:all .25s;
			}

			h2{ 
				color:$c-blue;
			}
		}
	}
}