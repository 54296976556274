

caption{
  background:$c-fern;
  color:#fff;
  font-size:2.4rem;
   font-weight: 600;
  text-align: left;
  padding:14px 10px;
  line-height:1em;
  border-bottom:1px solid #fff;
}

table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th,td{
  padding:10px 15px; 
  line-height: 1.2em;
}

th {
  background:#4b555a;
  color:#fff;
  font-size:1.8rem;
  font-weight: 600;
  text-align: left;
  border-right:1px solid #fff;
  border-bottom:1px solid #555f64;

  &:last-child{
    border-right:none;
  }
}

td {
  border-right:1px solid #555f64;
  border-bottom:1px solid #555f64;

  &:last-child{
    border-right:none;
  }
}

tr,
td,
th {
  vertical-align: middle;
}


// table{
//   margin:30px 0;
//   @include font-size(1.5);
//   line-height: 1.73em;
//   border-top:5px solid $prim-colour;

//   tr{
//     border-bottom:1px solid $prim-colour;
//   } 
//   th{
//     padding:10px;
//     text-transform: uppercase;
//     font-weight: 700;
//   }
//   td{
//     padding:10px;
//   }

//   caption{
//     text-transform: uppercase;
//     color:$prim-colour;
//     @include font-size(1.8);
//     font-weight: 700;
//     padding-bottom:10px;

//   }

// }

// .table-row{
//   margin-bottom: 20px;
//   clear: both;
//   margin: 0 0 10px;
//   overflow: hidden;
//   padding: 1px;
// }

// .table-row > label, legend{
//   float: left;
//   padding-right: 25px;
//   width: 25%;
//  }


// form > div > div, form > div > fieldset > div {
//   float: right;
//   width: 75%;
//   line-height: 25px;
// }