/*********************
Event Calendar
**********************/
body{
	.datepicker{
		max-width: 330px;
		td, th{
			border-radius:0;
		}
	}
}

.post-type-archive-tribe_events, .event-calendar, .single-tribe_venue{
	#subnav{
		position:absolute;
		bottom:calc(100% - 19px);
		left:0;

		ul{
			display: none;
		}
	}
	#content{
		float: none;
		margin:0;
		width:100%;
	}
	.breadcrumbs{
		@include media($medium-screen-up){
			margin-left:26%;
		}
	}

	h1{
		border-bottom:1px solid #1d2123;
		color: #1d2123;
		padding-bottom:8px;
		@include media($medium-screen-up){
			font-size:5rem;
		}
	}

	#tribe-events-pg-template{
		@include media($medium-screen-down){
			padding:0;
		}
	}

	#tribe-events{
		@media screen and (min-width:768px) and (max-width:1360px){
			padding-right:80px;
		}
		
		li:before{display:none;}
	}

	#tribe-events-bar{
		padding: 5px 0 15px;
		border-bottom: 1px solid #1d2123;

		#tribe-bar-form{
			background:transparent;

			label{
				color: #4b555a;
				font-size: 16px;
				font-family: 'Asap', sans-serif;
				font-weight: 400;
			}

			input[type="text"]{
				height: 44px;
				border:1px solid #000;
				border-radius:0;
				padding: 5px 16px;
				outline:none;
				color: #4b555a;
				font-size: 20px;
				font-style: normal !important;
				font-family: 'Asap', sans-serif;
			}

			input[type="submit"]{
				background:#003797;
				font-weight: 600;
				font-size: 18px;
				height: 44px;
				line-height: 44px;
				padding: 0 10px;

				@include media($small-screen-up){
					&:hover{
						background:#4e822a;
					}
					
				}

				@include media($large-screen-up){
					padding: 0 15px;
				}
				@include media($ex-large-screen-up){
					font-size: 20px;
					padding: 0 20px;
				}
			}

			.tribe-bar-filters{
				@media screen and (min-width:843px){
					display: block !important;
				}
			}

			.tribe-bar-filters-inner{
				&>*{
					padding-top:4px;
				}
				.tribe-bar-submit{
					padding-top:24px;
				}
			}

			.tribe-bar-views-inner{
				background:transparent;
				padding:0;

				.tribe-bar-views-list{
					margin-top:10px;
					z-index: 5;
				}

				.tribe-bar-views-option{

					a{
						background:transparent;
						color: #4b555a;
						font-size: 16px;
						padding-left: 20px;
					}
				}
			}

			.tribe-bar-views-open {
				.tribe-bar-views-option a{
					background:#e0e0e0;
				}
			}
		}

		
	}

	#tribe-events-content{
		margin-bottom: 0;
		&.tribe-events-list {
		    margin-bottom: 0;

		    #tribe-events-footer{
		    	margin-top:0;
		    }
		}

		.tribe-events-loop{
			@include media($ex-small-screen-up){
				display: flex;
				flex-wrap:wrap;
				margin:0 -10px;
			}
			@include media($small-screen-up){
				margin:0 -15px;
			}
		}

		.type-tribe_events{
			border:0;
			padding:0;
			margin-bottom:30px;

			@include media($ex-small-screen-up){
				width:50%;
				padding: 0 10px;
			}
			@include media($small-screen-up){
				padding: 0 15px;
			}
			@include media($medium-screen-up){
				width:100%;
			}
		}
		.cols-wrap{
			@include media($medium-screen-up){
				display: flex;
			}
		}

		.featured-image{
			border:1px solid #b9c3c8;
			position:relative;
			overflow: hidden;

			@include media($medium-screen-up){
				width:300px;
			}
			@include media($large-screen-up){
				width:370px;
			}

			@include media($small-screen-up){
				&:hover{
					h2{
						background-color:#003797;
					}
				}
			}

			.tribe-events-event-image{
				float: none;
				width:100%;
				margin:0;
			}

			img{
				width:100%;
				height: auto;
				max-height:none;

				@include media($medium-screen-up){
					min-width: 100%;
					min-height: 100%;
					width:auto;
					transform:translate(-50%, -50%);
					left:50%;
					top:50%;
					max-width: none;
					position:absolute;
				}
			}

			h2{
				position:absolute;
				bottom:0;
				left:0;
				width:100%;
				line-height: 1.1;
				font-size: 18px;
				background-color: #4e822a;
				transition: all 0.2s ease;

				@include media($small-screen-up){
					font-size: 20px;
				}

				a{
					display: block;
					padding: 10px 15px;
					color: #fff;
				}
			}
		}
		.text{
			border:1px solid #b9c3c8;

			@include media($medium-screen-down){
				border-top:0;
			}
			@include media($medium-screen-up){
				width:calc(100% - 300px);
				border-right:0;
				border-left:0;
			}
			@include media($large-screen-up){
				width:calc(100% - 370px);
			}
		}

		.tribe-events-event-meta{
			margin:0;
			color: #4e822a;
			font-size: 15px;
			line-height: 1.125;
			padding:0;
			border:0;
			background:transparent;

			@include media($small-screen-up){
				font-size: 16px;
			}

			@include media($large-screen-up){
				display: flex;
				border-bottom:1px solid #b9c3c8;
			}

			&>div{
				border:0;
				border-bottom:1px solid #b9c3c8;
				margin:0;
				padding: 10px 15px;

				@include media($small-screen-up){
					padding: 15px 30px;
				}
				@include media($large-screen-up){
					width:33.33333%;
					border:0;
					border-right:1px solid #b9c3c8;
					display: flex;
					width:100%;
					justify-content: center;
					padding: 32px 30px;

					&:last-child{
						border-right:0;
					}
				}

				
			}

			.tribe-event-schedule-details{
				.time{
					display: block;
				}
			}
			.tribe-events-venue-details{
				
				@include media($large-screen-up){
					padding-top: 18px;
					padding-bottom:18px;
				}

				a{
					color: #4e822a;
				}

				.tribe-address{
					display: block;
					margin-top:10px;

					@include media($large-screen-up){
						margin-top:18px;
					}
				}
			}

			.google-map{
				a{
					color: #4e822a;
					display: inline-block;
					line-height: 26px;
					padding-left:20px;
					text-decoration: underline;
					background:url(../img/icons/icon-location.png) no-repeat left center;
					-webkit-background-size:12px auto;
					background-size:12px auto;
					@include media($small-screen-up){
						padding-left:32px;
						-webkit-background-size:auto;
						background-size:auto;
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
		}

		.description.entry-summary{
			float: none;
			padding: 15px 30px;
			color: #4b555a;

			@include media($small-screen-down){
				padding: 10px 15px;
				&>*{
					display: none;
				}
			}

			@include media($large-screen-up){
				padding: 15px 0 15px 60px;
			}

			p{
				font-size: 16px;
				line-height: 1.53;
				font-family: 'Asap', sans-serif;
			}

			.tribe-events-read-more{
				font-weight: 700;
				color: #003797;
				display: inline-block;
				line-height: 24px;
				padding-left:20px;
				text-decoration: none;
				font-size: 15px;
				background:url(../img/icons/icon-info.png) no-repeat left center;
				-webkit-background-size:14px auto;
					background-size:14px auto;
				@include media($small-screen-up){
					padding-left:32px;
					-webkit-background-size:auto;
					background-size:auto;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}

		.tribe-events-ical{
			display: none !important;
		}
	}

	.tribe-events-venue-meta{
		h2{
			margin-bottom:20px;
		}
		.tribe-events-gmap{
			color: #4e822a;
			display: inline-block;
			line-height: 26px;
			padding-left:20px;
			text-decoration: underline;
			background:url(../img/icons/icon-location.png) no-repeat left center;
			-webkit-background-size:12px auto;
			background-size:12px auto;
			font-size: 16px;
			
			@include media($small-screen-up){
				padding-left:32px;
				-webkit-background-size:auto;
				background-size:auto;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	

}

body .c-w1 #tribe-events-footer{
		margin:30px 0 0 0;
		border-top:1px solid #003797;
		border-bottom:1px solid #003797;
		padding: 0 10px;

		ul{
			margin:0;

			&:after{
				content:'';
				display: table;
				clear: both;
			}
		}

		&.hide{
			display: none;
		}

		li{
			padding:0;
			margin:0;
			width:48%;
			&:before{
				display: none;
			}
		}

		a{
			color: #003797;
			font-weight: 700;
			font-size: 15px;
			display: block;
			padding: 15px 0;
			background:transparent;

			@include media($small-screen-up){
				font-size: 16px;
				padding: 18px 0;
				&:hover{
					color: #4e822a;
				}
			}

			span{
				font-size: 0;
				display: inline-block;
    			vertical-align: middle;
    			position:static;

				&:before{
					font-size: 14px;
					font-weight: 400;
					line-height: 1;
				}
			}
		}
		.tribe-events-nav-next{
			text-align: right;
			float: right;
			a{
				padding-left:15px;
				span{
					margin-left: 5px;
					@include media($small-screen-up){
						margin-left: 14px;
					}

					&:before{
						content:'\276F';
					}
				}
			}
		}
		.tribe-events-nav-previous{
			float: left;
			a{
				padding-right:15px;
				span{
					margin-right: 5px;
					@include media($small-screen-up){
						margin-right: 14px;
					}

					&:before{
						content:'\276E';
					}
				}
			}
		}
	}

.single-tribe_events{
	#subnav{
		display: none;
	}
	#content{
		float: none;
		width:100%;
		margin:0;
	}
	.tribe-events-schedule{
		margin-bottom:20px;
		.time{
			display: block;
		}
	}
}

table.tribe-events-calendar{
	caption{display:none;}
}