/*******************************************
Navigation
********************************************/

#menu-toggle{
	display: block;
	position: absolute;
	font-size:15px;
	position: absolute;
	top:15px;
	right:$global-edge-padding;
	height:50px;
	padding:10px 10px 0px 10px;
	text-decoration:none;
	z-index: 100;
	font-size:10px;
	border-radius:0;
	background:$c-cornflower;
	@include media($medium-screen-up) {
		display:none;
	}
	

	.icon{
		position: relative;
		height:30px;
		width:30px;
		line-height: 30px;
		color:#fff;
		margin:0 auto;
	
		&:after, & span:before, & span:after {
			border-radius: 1px;
			height: 2px; 
			width: 29px;
			background: #fff;
			display: block;
			content: '';
			position: absolute;
			right:0px;
			cursor: pointer;		
		}
		//top bar
		& span:before {
			top: 4px;
		}
		//middle bar
		& span:after {
			top: 11px;
		}
		//bottom bar
		&:after{
			top:18px;
		} 
		 
		& span:before, & span:after {
			transition: all 500ms ease-in;
		}
	}

	&.menu-open{
		.text{
			// opacity: 0;
		}
		.icon{
			
			&:after{
				transform: translate(-999px, 0)
			}
			
			span:before {
				transform: translate(0, 7px) rotate(45deg);
			}
			span:after {
				transform: translate(0, 0px) rotate(-45deg);
			}
		}
	}
}

#menu-close{
	@include media($medium-screen-up) {
		display:none;
	}
	background:#000;
	color:#fff;
	width:100%;
	font-weight: 600;
	text-align:center;
	padding: 0 27px;
	height: 50px;
	line-height: 50px;

	&:before, .text{
		display: inline-block;
		vertical-align: middle;
	}

	&:before{
		content:'';
		width:20px;
		height: 20px;
		background:url(../img/icons/icon-close.png) no-repeat center;
		background-size:100% auto;
		margin-right:5px;
	}
}

/*** mobile nav ******/
@include media($medium-screen-down){
	#wrapper{
		position: relative;
		right:0;
		transition:all .5s;
	}
	#wrapper.menu-open{
		right:100%;
		position:fixed;
		width:100%;
	}
	.menu-wrap{
		position: fixed;
		right:-100%;
		top:0;
		width:100%;
		height: 100vh;
		overflow: scroll;
		background:#fff;
		// box-shadow:inset 1px 0px 20px 0px rgba(0, 0, 0, 0.75);
		transition:all .5s;
		//-webkit-overflow-scrolling: touch;
		padding-bottom:50px;

		&.menu-open{
			right:0;

		}

		.social-links{
			padding-bottom:50px;
			li{
				margin:0 1px;

				&:first-child{
					margin-left:1px;
				}
			}
		}
	}

	#menu-secondary-navigation{
		border-top:2px solid #fff;
		& > li{
			background:$c-cornflower;
			&:first-child{
				border-top:none;
			}

			a{
				color:#fff;
				display: block;
				position: relative;
				text-decoration: none;
			}

			&.current_page_item > a{
				// background:#fff;
				// color:#555f64;
			}

			&>a{
				padding:10px 27px;
				font-size:16px;
				font-weight: 600;
				&:hover{
					text-decoration: none;
				}
			}

			&.hasChildren{
				& > a{
					padding-right:52px;

					&:after{
						content:'';
						position:absolute;
						right:27px;
						top:15px;
						width:13px;
						height: 14px;
						background-image:url(../img/icons/menu-arrow.png);
						background-repeat:no-repeat;
						background-size:100% auto;
						transform-origin:center;
					}
				}

				&.open{
					& > a:after{
						transform:rotate(180deg);
					}
				}
			}

			&.open{
				background:$c-fern;
				border-top:2px solid #fff;
				border-bottom:2px solid #fff;
			}

			&>.sub-menu{
				padding: 0 14px;
				&>li{
					&>a{
						padding: 10px 13px;
					}
					&.hasChildren{
						& > a{
							padding-right:37px;

							&:after{
								content:'';
								position:absolute;
								right:13px;
								top:13px;
								width:13px;
								height: 14px;
								background-image:url(../img/icons/menu-arrow.png);
								background-repeat:no-repeat;
								background-size:100% auto;
								transform-origin:center;
							}
						}

						&.open{
							background:#fff;
							padding: 2px 0 7px;

							&>.sub-menu{
								margin-left:30px;

								&>li{
									&.current-menu-ancestor, &.current_page_item{
										&>a:before{
											background:#4e822a;
										}
									}
									&>a:before{
										content:'';
										width:5px;
										height: 5px;
										background:#8f9ea6;
										border-radius:50%;
										top:13px;
									}
								}

								.sub-menu{
									display: block;
									margin-left:10px;
								}

								a{
									padding: 7px 10px;

									&:before{
										content:'-';
										position:absolute;
										left:0;
										top:6px;
									}
								}
							}

							a{
								color: #83949d;
							}
							& > a:after{
								background-image:url(../img/icons/menu-arrow-grey.png);
								transform:rotate(180deg);
							}

							&.current-menu-ancestor, .current-menu-ancestor, &.current_page_item, .current_page_item{
								&>a{
									color: #000;
								}
							}
						}

						
					}
				}
			}

			&.contact{
				border-top:2px solid #fff;
				background:#ffb400;

				a{
					color: #000;
				}

				&.open{
					background:$c-fern;

					a{
						color: #fff;
					}
				}
			}
		}

		.sub-menu{
			display: none;
			li{
				a{
					font-size:13px;
					font-weight: 400;
					padding:10px 10px 10px 20px;
				}

			}
		}

		.open > .sub-menu{
			display: block;
		}
	}

	.m-mainnav{
		padding: 16px 14px 0 14px;

		&>ul>li{
			margin-bottom:13px;
			border:2px solid $c-cornflower;

			&>a{
				display: block;
				color: $c-cornflower;
				font-weight: 600;
				font-size: 16px;
				padding: 6px 36px 6px 11px;
				text-decoration: none;
				position:relative;
			}

			&.hasChildren{
				& > a{
					padding-right:37px;

					&:after{
						content:'';
						position:absolute;
						right:11px;
						top:12px;
						width:13px;
						height: 14px;
						background-image:url(../img/icons/menu-arrow-blue.png);
						background-repeat:no-repeat;
						background-size:100% auto;
						transform-origin:center;
					}
				}

				&.open>.sub-menu{
					display: block;
				}
				&.open{
					& > a:after{
						transform:rotate(180deg);
					}
				}
			}

			&>.sub-menu{
				margin-left:12px;
				padding:2px 0 10px 0;
				display: none;
				line-height:1;

				.current-menu-ancestor, .current_page_item{
					&>a{
						color: #000;
					}
				}

				&>li{
					&.current-menu-ancestor, &.current_page_item{
						&>a:before{
							background:#4e822a;
						}
					}
					&>a:before{
						content:'';
						width:5px;
						height: 5px;
						background:#8f9ea6;
						border-radius:50%;
						top:14px;
					}
				}

				.sub-menu{
					display: block;
					margin-left:10px;
				}

				a{
					padding: 10px 12px;
					position:relative;
					display: block;
					color: #83949d;

					&:before{
						content:'-';
						position:absolute;
						left:0;
						top:8px;
					}
				}
			}
		}
	}

	.menu-search-form{
		padding: 0 14px 14px 14px;

		form{
			padding:0 41px 0 0;
			position:relative;
		}

		input[type="text"]{
			border:1px solid #647378;
			border-right:0;
			width:100%;
			height: 40px;
			padding: 0 12px;
			margin:0;
			font-size: 13px;
			color: #4b555a;
		}
		input[type="submit"]{
			height: 40px;
			width:40px;
			font-size: 0;
			background:url(../img/icons/icon-search.png) no-repeat center;
			background-size:100% auto;
			position:absolute;
			right:0;
			top:0;
		}
	}
}

/** desktop nav ***/
@include media($medium-screen-up){
	.h-w1{
		background:$c-cornflower;
		z-index: 100;/*to avoid search button appearing above*/
    	position: relative;
	}
	#menu-secondary-navigation{
		display: flex;
		justify-content:space-between;
		flex-wrap: wrap;

		.d-hide{
			display: none;
		}
		
		& > li{
			display: inline-block;
			position: relative;
			// white-space: nowrap;
			
			& > a{
				display: block;
				color:#fff;
				padding:20px 10px; 
				font-size:1.2rem;
				font-size:12px;
				text-align: center;
				border-left:3px solid transparent;
				border-right:3px solid transparent;
				transition:all .25s;
				height: 100%;
				@include media($large-screen-up){
					padding:20px 12px; 
					font-size:1.6rem;
					font-size:16px;
				}
				@include media($ex-large-screen-up){
					padding:20px 20px; 
					font-size:1.8rem;
					font-size:18px;
				}
			}

			&:hover > a,
			& > a:focus,
			&.current_page_item > a,
			&.current_page_ancestor > a{
				border-color:#fff;
				background:$c-fern;
				text-decoration: none;
			} 

			&.current_page_item,
			&.current_page_ancestor{
				& > a:after{
					content:'';
					position: absolute;
					top:100%;
					left:50%;
					margin-left:-14px;
					border-top:12px solid $c-fern;
					border-left:12px solid transparent;
					border-right:12px solid transparent;
				} 
			}

			&:first-child{
				&:hover .sub-menu{
					left:0;
					margin:0;
				}
			}

			/** contact button ****/

			&#menu-item-72{
				&>a{
					background:#ffb400;
					color: #000;
					font-weight: 600;

					&:hover{
						color: #fff !important;
					}
				}

				&:hover > a{
					background:$c-fern;
					color: #fff;
				}

				a{
					border-color:#fff;
				}
 
				
				&.current_page_ancestor a,
				&.current_page_item a{
					background:$c-fern;
					color: #fff;

					&:hover{
						color: #000;
					}
				}

				.sub-menu{
					right: 0;
				    left: auto;
				    margin:0;
				}
			}
		}

		.menu-item-66{
			.sub-menu{
				left:0;
				margin-left:0;
			}
		}
		
		.sub-menu{
			// display: none;
			opacity:0;
			position: absolute;
			top:100%;
			left:50%;
			margin-left:-150px;
			width:300px;
			z-index: 10;
			overflow: hidden;
			max-height: 0;
			transition:max-height .45s;

			li{

				/*hide generated overview links */
				&.opt-overview{display:none;}

				a{
					display: block;
					color:#fff;
					padding:10px 20px; 
					font-size:1.6rem;
					font-size:16px;
					transition:all .25s;

					&:hover,
					&:focus{
						background:#fff !important;
						color:#000;
						text-decoration: none;
					}
				}
			}

			.sub-menu{display:none;}
		}

		li:hover .sub-menu,
		a:focus + .sub-menu{
			display: block;
			opacity:1;
			max-height: 600px;
			background:$c-fern;
			padding-top:10px;
			padding-bottom:10px;
			// width:100%;
			border-left:3px solid #fff;
			border-right:3px solid #fff;
			border-bottom:3px solid #fff;
		}
	}

	.m-mainnav, .menu-search-form{
		display: none;
	}
}

.menu-block{
	border-top:1px solid #fff;
	padding:5px 27px 0;
	color:#4b555a; 
	text-align:center;
	font-size: 13px;
	@include media($medium-screen-up){
		display: none;
	}

	h2{
		font-size:20px;
		color: #4b555a;
		font-weight: 800;
		margin:0 0 6px 0;
	}

	p{
		line-height: 1.923;
		margin-bottom:0;
	}

	a{
		color:$c-cornflower;
		text-decoration: underline;
	}
}

/* who/what/how*/
.mainnav {
	margin-bottom:10px;
	transition:all .25s;
	@include media($medium-screen-up){
		position: absolute;
		top:33px;
		right:75px;
	}
	@include media($ex-large-screen-up){
		right:404px;
	}
	@media screen and (min-width:768px) and (max-width:1400px){
		top:22px;
	}
	.menu{
		display: flex;
	}

	li{
		display:inline-block;
		vertical-align: middle;
		width:33%; 
		margin-right: 1%;
		@include media($medium-screen-up){
			width:140px;
		}

		@include media($ex-large-screen-up){
			width:170px;
		}

		&:last-child{margin-right:0;}

		a{
			display: block;
			height: 100%;
			padding:6px 19px;
			border:2px solid $c-cornflower;
			color:#555f64;
			text-align: center;
			font-size:11px;
			line-height: 1.2em;
			transition:all .25s;
			@include media($medium-screen-up){
				padding:10px; 
				font-size:12px;
			}
			@include media($ex-large-screen-up){
				padding:12px;
				font-size:15px;
			}
			span{
				display: block;
				margin:0 auto;
				color:$c-cornflower;
				font-size:15px;
				font-weight: 800;
				line-height: 1.2em;
				@include media($medium-screen-up){
					font-size:28px;
				}
			}

			&:hover{
				text-decoration:none;
				background:$c-blue;
				color:#fff;
				span{
					color:#fff;
				}

			}
		}
	}
}

/*** sticky **********/ 

.sticky{
	transition:all .25s;

	&.headroom--unpinned:not(.search-open):not(.mobile-menu-open){
		opacity:0;
		visibility: hidden;
	}
	@include media($medium-screen-up){
		&.headroom--unpinned:not(.search-open):not(.mobile-menu-open){
			opacity:1;
			visibility: visible;
		}

		#menu-secondary-navigation>li>a{
			padding-top:5px;
			padding-bottom:5px;
		}
		
		#logo{
			max-width: 140px;
		}

		.mainnav{
			top:16px;
		}
			.mainnav li a{
				font-size:0;
			}


		.search-form{
			top:16px;

			input[type='text']{
				height:60px;
			}

			input[type='submit']{
				padding-top:60px;
				width:60px;
			}
		}
	}
}

//keep visible if open
.menu-open .sticky{
	&.headroom--unpinned{
		opacity:1;
	}
}

.no-csspositionsticky .sticky{
	width:100%;
	margin:0 !important;
}

.no-csspositionsticky .headroom--top{
	position:relative!important;
}