/*******************************************
Font Sizer
*******************************************/
#wrapper{
	@include media($medium-screen-down){
		padding-bottom:55px;
	}
	@include media($small-screen-down){
		padding-bottom:110px;
	}
}
#main { 
	@include media($medium-screen-up){
		position: relative; 
	}
}
.font-sizer-wrap{
	position: fixed;
	bottom:0;
	right:0;
	z-index: 100;
	width:100%;
	padding:5px;
	display:flex;
	justify-content:space-between;
	background:#fff;
	opacity:0;
	visibility:hidden;
	transition:all .25s;
	@include media($small-screen-up){
		flex-direction: row;
		opacity:1;
		visibility: visible;
	}
	@include media($medium-screen-up){
		display: block;
		// top:30px;
		top:255px;
		bottom:auto;
		right:0;
		width:80px;
		padding:0;
		background:transparent;
		max-height: calc(100% - 255px);
	}
	@media screen and (min-width:768px) and (max-width:1400px){
		top:210px;
	}

	&.scrollDown {
		opacity:1;
		visibility: visible;
	}

	&.active{
		flex-direction:column;
	}

	.control{
		background:$c-fern;
		padding:5px 15px;
		margin-bottom: 5px;
		@include media($medium-screen-up){
			padding:15px 15px 10px;
		}

		&.font-sizer{
			background-image:url(../img/icons/icon-text.png);
			background-size:40px auto;
			background-position: 12px 9px;
			background-repeat:no-repeat;
			width:100%;
    		margin-right: 5px;
    		padding-left:75px;
    		@include media($small-screen-up){
    			flex: 1;
    			width:auto;
    		}
    		@include media($medium-screen-up){
    			margin-right: 0;
    			padding-top:50px;
    			padding-left:15px;
    			background-size:auto;
    			background-position:center 7px;
    		}
		}

		&.print{
			@include media($medium-screen-down){
				display: none;
			}
		}

		&.listen{
			position:relative;
			transition: all 0.4s ease;
			width:100%;
			@include media($small-screen-up){
				width:auto;
				&.active{
    				flex: 3;
    			}

			}
			@include media($medium-screen-up){
    			position:absolute;
    			right:0;
    			top:100%;
    			width:80px;

    			&.active{
    				width:368px;
    			}
    		}

    		.btn-text{
    			transition: all 0.4s ease;
    		}

    		.readspeaker-wrap{
    			position:absolute;
    			left:0;
    			top:0;
    			max-width: 100%;
    			max-height: 100%;
    			overflow:hidden;
    			padding: 10px 15px 10px 50px;
    			opacity: 0;
    			visibility: hidden;
    			transition: all 0.4s ease;

    			.rsbtn{
    				margin:0;
    			}

    			@include media($medium-screen-up){
    				padding: 40px 35px 10px;
    			}
    		}
    		.rs-close{
    			width:26px;
    			height: 26px;
    			border-radius: 50%;
    			background:#fff;
    			text-decoration: none;
    			position:absolute;
    			left:10px;
    			top:10px;
    			overflow:hidden;
    			white-space: nowrap;
    			text-indent: 200px;
    			opacity: 0;
    			visibility: hidden;

    			@include media($medium-screen-up){
    				width:43px;
    				height: 43px;
    				left:-10px;
    				top:-10px;
    			}

    			&:before, &:after{
    				content:'';
    				width:16px;
    				height: 3px;
    				position:absolute;
    				left:5px;
    				top:12px;
    				transform-origin: center;
    				background: #003797;

    				@include media($medium-screen-up){
	    				width:20px;
	    				height: 5px;
	    				left:12px;
	    				top:19px;
	    			}
    			}
    			&:before{
    				transform:rotate(-45deg);
    			}
    			&:after{
    				transform:rotate(45deg);
    			}
    		}

    		&.active{
    			.btn-text{
    				opacity: 0;
    				visibility: hidden;
    			}
    			.readspeaker-wrap, .rs-close{
    				opacity: 1;
    				visibility: visible;
    			}

    		}
		}
	}

	

	button{
		background:transparent;
		padding:0;
		text-transform: none;
		font-weight: normal;
	
		&:last-child{
			margin-bottom: 0;
		}

		.fa{
			font-size:25px;
			background:#fff;
			color:$c-blue;
			background-repeat: no-repeat;
			width:35px;
			height:35px;
			line-height: 35px;
			padding:0;
			display: block;
			transition:all .25s;

			@include media($medium-screen-up){
				width:50px;
				height:50px;
				line-height: 50px;
				margin-left: 0;
				margin-bottom: 9px;
				
			}
		}

		&:focus .fa,
		&:hover .fa{
			color:#fff;
			background:$c-blue;
			transition:all .25s;
		}

		&.btn-notext{
			span{
				@include elementInvisible;
			}
		}

		&.btn-text{
			display: flex;
			    align-items: center;
			@include media($medium-screen-up){
				display: block;
			}

			span{
				order:1;
				margin-right: 10px;
			}

			.fa{
				order:2;
			}
		}
	}
}
